/// <reference path="../../_all.ts" />
/// <reference path="../models/invoicing/InvoiceCompact.ts" />
/// <reference path="../models/invoicing/CreateInvoiceRequest.ts" />
/// <reference path="../models/invoicing/DefaultHoursRequest.ts" />
/// <reference path="../models/invoicing/DetailedInvoice.ts" />
/// <reference path="../models/invoicing/InvoicingOverview.ts" />
/// <reference path="../models/invoicing/InvoicingUmbrellaDashboard.ts" />
/// <reference path="../models/invoicing/invoicePreference.ts" />
/// <reference path="../models/invoicing/TimesheetCompactUmbrella.ts" />
/// <reference path="../enums/InvoiceCreationStatus.ts" />
/// <reference path="../../shared/models/Invoice.ts" />
/// <reference path="../models/invoicing/FullInvoice.ts" />
module Shared {
    interface IBrooksonInvoicingServiceCalcTotalsReturn {
        gross: number;
        net: number;
    }

    export class BrooksonInvoicingService {
        static $inject = ['$http', 'shared.config'];

        public serviceBase: string;

        constructor(
            private $http: Shared.IBrooksonHttp,
            private sharedConfig
        ) {
            this.init();
        }

        init = (): void => {
            this.serviceBase = this.sharedConfig.baseApiUrl;
        }

        getInvoicesByType = (pageSize: number, pageNo: number, invoiceType: number, ledgerStatus: number): ng.IPromise<Array<Shared.Invoicing.InvoiceCompact>> => {
            let requestData = {
                pageNo: pageNo,
                pageSize: pageSize,
                ledgerStatus: ledgerStatus,
                invoiceType: invoiceType
            };

            return this.$http.post(this.serviceBase + 'api/Invoicing/GetInvoices', requestData)
                .then((response: ng.IHttpPromiseCallbackArg<Array<Shared.Invoicing.InvoiceCompact>>): Array<Shared.Invoicing.InvoiceCompact> => {
                    return response.data;
                });
        }

        createInvoice = (invoice: Shared.Invoicing.Invoice): ng.IPromise<Shared.InvoiceCreationStatus> => {
            let requestData = {
                invoice: invoice
            };

            return this.$http.post(this.serviceBase + 'api/Invoicing/CreateInvoice', requestData)
                .then((response: ng.IHttpPromiseCallbackArg<Shared.InvoiceCreationStatus>): Shared.InvoiceCreationStatus => {
                    return response.data;
                });
        }

        createInvoiceOverride = (invoice: Shared.Invoicing.Invoice): ng.IPromise<Shared.InvoiceCreationStatus> => {
            let requestData = {
                invoice: invoice
            };

            return this.$http.post(this.serviceBase + 'api/Invoicing/CreateInvoiceOverride', requestData)
                .then((response: ng.IHttpPromiseCallbackArg<Shared.InvoiceCreationStatus>): Shared.InvoiceCreationStatus => {
                    return response.data;
                });
        }

        getInvoice = (id: string): ng.IPromise<Shared.Invoicing.DetailedInvoice> => {
            return this.$http.get(this.serviceBase + 'api/Invoicing/GetDetailedInvoiceById/' + id.replace(":", '-'))
                .then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.DetailedInvoice>): Shared.Invoicing.DetailedInvoice => {
                    return response.data;
                });
        }

        getInvoicingOverview = (): ng.IPromise<Shared.Invoicing.InvoicingOverview> => {
            return this.$http.get(this.serviceBase + 'api/Invoicing/GetInvoicingOverview', {
                silentMode: true
            })
                .then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.InvoicingOverview>): Shared.Invoicing.InvoicingOverview => {
                    return response.data;
                });
        }

        getTimesheetUmbreallaOverview = (): ng.IPromise<Shared.Invoicing.TimesheetCompactUmbrella> => {
            return this.$http.get(this.serviceBase + 'api/Invoicing/GetTimesheetUmbrellaCompact', {
                silentMode: true
            })
                .then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.TimesheetCompactUmbrella>): Shared.Invoicing.TimesheetCompactUmbrella => {
                    return response.data;
                });
        }

        getInvoicingUmbrellaOverview = (): ng.IPromise<Shared.Invoicing.InvoicingUmbrellaDashboard> => {
            return this.$http.get(this.serviceBase + 'api/Invoicing/GetInvoicingUmbrellaDashboard', {
                silentMode: true
            })
                .then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.InvoicingUmbrellaDashboard>): Shared.Invoicing.InvoicingUmbrellaDashboard => {
                    return response.data;
                });
        }

        saveInvoice = (invoice: Shared.Invoicing.Invoice, silentMode?: boolean): ng.IPromise<boolean> => {
            let requestData = {
                invoice: invoice
            };

            return this.$http.post('api/Invoicing/SaveInvoice', requestData, {
                silentMode: silentMode ? silentMode : false
            })
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        updateDefaultHoursPerDay = (defaultHours: Shared.Invoicing.DefaultHoursRequest): ng.IPromise<boolean> => {
            return this.$http.post('api/Invoicing/UpdateDefaultHoursPerDay', defaultHours).then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                return response.data;
            });
        }

        getSavedInvoice = (invoiceId: string, silentMode?: boolean): ng.IPromise<Shared.SavedInvoice> => {
            return this.$http.get(`api/Invoicing/GetSavedInvoice/${invoiceId}`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.SavedInvoice>): Shared.SavedInvoice => {
                return response.data;
            });
        }

        getSavedInvoiceForCrediting = (invoiceId: string, silentMode?: boolean): ng.IPromise<Shared.SavedInvoice> => {
            var id = invoiceId.replace(":","-");
            return this.$http.get(`api/Invoicing/GetInvoiceForCrediting/${id}`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.SavedInvoice>): Shared.SavedInvoice => {
                return response.data;
            });
        }

        deleteSavedInvoice = (invoiceId: number, silentMode?: boolean): ng.IPromise<boolean> => {
            return this.$http.delete(`api/Invoicing/DeleteSavedInvoice/${invoiceId}`, {
                silentMode: silentMode ? silentMode : false
            })
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        deleteSavedFullInvoice = (invoiceId: number, silentMode?: boolean): ng.IPromise<boolean> => {
            return this.$http.delete(`api/Invoicing/DeleteSavedFullInvoice/${invoiceId}`, {
                silentMode: silentMode ? silentMode : false
            })
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        calculateTotals = (invoice: Shared.Invoicing.DetailedInvoice): IBrooksonInvoicingServiceCalcTotalsReturn => {
            let gross = 0;
            let net = 0;

            for (let index in invoice.appliedRates) {
                if (invoice.appliedRates.hasOwnProperty(index)) {
                    let rate = invoice.appliedRates[index];
                    if (rate.amountGross) {
                        gross += rate.amountGross;
                    }
                    if (rate.amountNet) {
                        net += rate.amountNet;
                    }
                }
            }

            gross += invoice.expenses ? invoice.expenses : 0;

            return {
                gross: gross,
                net: net
            };
        }

        hidePublicSectorModal = (assignment: Shared.Assignment): ng.IPromise<boolean> => {
            return this.$http.post(this.serviceBase + "api/Invoicing/HidePublicSectorModal", assignment).then((response: ng.IHttpPromiseCallbackArg<boolean>) => { return response.data; });
        }

        creditInvoice = (invoiceId: string, replacementInvoice?: Shared.Invoicing.Invoice): ng.IPromise<boolean> => {
            var data = {
                InvoiceId: invoiceId,
                ReplacementInvoice: replacementInvoice
            };
            return this.$http.post(this.serviceBase + "api/Invoicing/creditInvoice", data).then((response: ng.IHttpPromiseCallbackArg<boolean>) => { 
              return response.data; 
            });
        }

        saveInvoicePreferences = (invoicePreferences: Shared.Invoicing.InvoicePreference): ng.IPromise<boolean> => {
            return this.$http.post(this.serviceBase + "api/Invoicing/saveInvoicePreferences", invoicePreferences).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        getCompanyInvoicePreferences = (): ng.IPromise<Shared.Invoicing.InvoicePreference> => {
            return this.$http.get(this.serviceBase + "api/Invoicing/getCompanyInvoicePreferences").then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.InvoicePreference>) => {
                return response.data;
            });
        }

        saveFullInvoice = (fullinvoice: Shared.Invoicing.FullInvoice): ng.IPromise<Shared.Invoicing.FullInvoice> => {
            return this.$http.post(this.serviceBase + "api/Invoicing/saveFullInvoice", fullinvoice).then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.FullInvoice>) => {
                return response.data;
            });
        }

        getFullInvoiceById = (fullinvoiceId: number): ng.IPromise<Shared.Invoicing.FullInvoice> => {
            let model = {
                fullinvoiceId: fullinvoiceId
            };

            return this.$http.post(this.serviceBase + "api/Invoicing/getFullInvoiceById", model).then((response: ng.IHttpPromiseCallbackArg<Shared.Invoicing.FullInvoice>) => {
                return response.data;
            });
        }

        getAttachmentsById = (fullinvoiceId: number): ng.IPromise<Array<Shared.Invoicing.FullInvoiceAttachment>> => {
            let model = {
                fullinvoiceId: fullinvoiceId
            };

            return this.$http.post(this.serviceBase + "api/Invoicing/getAttachmentsById", model).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.Invoicing.FullInvoiceAttachment>>) => {
                return response.data;
            });
        }

        removeAttachmentById = (fullInvoiceId: number, attachmentId: number, azureFilename: string): ng.IPromise<boolean> => {
            var model = {
                fullinvoiceId: fullInvoiceId,
                attachmentId: attachmentId,
                azureFileName: azureFilename
            };

            return this.$http.post(this.serviceBase + "api/Invoicing/removeAttachmentById", model).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        getInvoicePdfSharedAccessSignatureUrl = (invoiceNumber: string): ng.IPromise<string> => {
            var model = {
                invoiceNumber: invoiceNumber
            };

            return this.$http.post(this.serviceBase + "api/Invoicing/InvoicePdfSharedAccessSignatureUrl", model).then((response: ng.IHttpPromiseCallbackArg<string>) => {
                return response.data;
            });
        }

        downloadInvoicePdf = (invoiceNumber: string): any => {
            var model = {
                invoiceNumber: invoiceNumber
            };

            return this.$http.post(this.serviceBase + "Invoicing/DownloadInvoicePdf", model).then((response: ng.IHttpPromiseCallbackArg<string>) => {
                return response.data;
            });
        }

        getCurrentVatrate = (vatBusPostingGroup: string, vatProdPostingGroup: string): ng.IPromise<number> => {
            var model = {
                vatBusPostingGroup: vatBusPostingGroup,
                vatProdPostingGroup: vatProdPostingGroup
            };
            return this.$http.post(this.serviceBase + 'api/invoicing/getCurrentVatrate', model).then((response: ng.IHttpPromiseCallbackArg<number>) => {
                return response.data;
            });
        }

        determineVatStatusByPeriodEndDate = (periodEndDate: Date): ng.IPromise<number> => {
            var model = {
                periodEndDate: periodEndDate
            };
            return this.$http.post(this.serviceBase + 'api/invoicing/determineVatStatusByPeriodEndDate', model).then((response: ng.IHttpPromiseCallbackArg<number>) => {
                return response.data;
            });
        }

        umbrellaCheck = (companyRef : string): ng.IPromise<boolean> => {
            var model = {
                CompanyRef : companyRef
            };
            return this.$http.post(this.serviceBase + 'api/invoicing/IsUmbrellaCompany', model).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }
    }
}

angular
    .module('app.shared')
    .service('invoicingSrv', Shared.BrooksonInvoicingService);