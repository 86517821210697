/// <reference path="../../services/brookson.services.task-planner.ts" />
/// <reference path="./../../models/UtrNumber.ts" />

module Shared {
    'use strict';

    export interface IBrooksonConfirmSelfAssessmentController extends IConfirmTaxPack {
        taxPackStatus: number;
        payBeforeApproval: boolean;    
    }

    export class BrooksonConfirmSelfAssessmentController implements IBrooksonConfirmSelfAssessmentController {

        public action: Shared.TaskAction;
        public approved: boolean = false;
        public taxPackStatus: number;
        public payBeforeApproval: boolean = false;
        public utrNumber: UtrNumber;
        public utrNumberUpdated: boolean = true;

        static $inject: Array<string> = ['taskPlannerSrv','brookson.navigation'];

        constructor(
            private data: Shared.TaskPlannerSrv,
            private brooksonNavigation: Shared.IBrooksonNavigation
        ) {
            data.getTaxPackStatus().then((status: number) => {
                this.taxPackStatus = status;
            });
            data.getIsPayBeforeSubmit().then((result: boolean) => {
                this.payBeforeApproval = result;
            });
            data.getUtrNumber().then((result: UtrNumber) => {
                this.utrNumber = result;
            });
        }

        public confirmTax = () => {
            this.data.updatePackStatus().then(() => {
                this.approved = true;
            });
        }

        public confirmStep = (step: number) => {
            this.data.confirmAccountsStep(step).then((result) => {
                if (result) {
                    this.data.getTaxPackStatus().then((status: number) => {
                        this.taxPackStatus = status;
                    });
                }
            });
        }

        public get taxPackAvailableToComplete(): boolean {
            return this.taxPackStatus === -1 || this.taxPackStatus ===0 || this.taxPackStatus === -3 ;
        }

        public get taxPackAvailableToConfirm(): boolean {
            return this.taxPackStatus === 3;
        }

        public get taxPackConfirmed(): boolean {
            return this.taxPackStatus === 1;
        }

        public get taxPackFiled(): boolean {
            return this.taxPackStatus === 99;
        }

        public get hasApproved(): boolean {
            return this.taxPackStatus === 2;
        }

        showUtrSection = (): boolean => {
            if(!this.taxPackFiled) 
            {
                if(this.showAwaitingUtrNumber() || this.showInvalidUtrNumber()) return true;
            }
            return false;
        }

        showAwaitingUtrNumber = (): boolean => {
            if (!this.utrNumber) return false;
            return !this.utrNumber.utrNumber;
        };

        showInvalidUtrNumber = (): boolean => {
            if (!this.utrNumber) return false;
            return !new RegExp("^[0-9]{10}$").test(this.utrNumber.utrNumber);
        };

        public gotoSelfAssessment = () => {
            this.brooksonNavigation.personalTax.selfAssessment();
        }

        public gotoComfirmAndPay = () => {
            this.brooksonNavigation.personalTax.selfAssessmentTAndCs();
        }
    }

    export interface IConfirmTaxPack {
        action: any;
    }

    export const BrooksonConfirmSelfAssessment: ng.IComponentOptions = {
        bindings: {
            action: '='
        },
        controller: BrooksonConfirmSelfAssessmentController,
        templateUrl: 'src/app/shared/views/ui/brookson.ui.confirm.self.assessment.html',
        transclude: true
    };
}

angular
        .module('app.shared')
        .component('brooksonConfirmSelfAssessment', Shared.BrooksonConfirmSelfAssessment);