/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var appShared = angular.module("app.shared");

    appShared.directive('brooksonInputText', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngMinlength: '=',
                ngMaxlength: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngPattern: '=',
                ngPatternMessage: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.inputType = 'text';
                    // $scope.expression = /^\d{2}$/;
                    // $scope.expression =  $scope.ngPattern;
                }
            ]
        };
    });

    appShared.directive('brooksonInputEmail', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                ngMinlength: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.inputType = 'email';
                }
            ]
        };
    });

    appShared.directive('brooksonInputPassword', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.inputType = 'password';
                    $scope.ngMinlength = 8;
                }
            ]
        };
    });

    appShared.directive('brooksonInputSelect', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.select.html',
            scope: {
                ngModel: '=',
                ngChange: '=',
                ngDisabled: '=',
                optionsData: '=',
                optionsExpression: '@',
                field: '@',
                isRequired: '@',
                label: '@',
                ngRepeat: '@',
                defaultOption: '@',
                additionalData: '=',
                noDefault: '=',
                showSpinner: '=',
                viewOnly: '=',
                viewOnlyProperty: '@'
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
                scope.ngModelIsAnObject = false;

                if (!scope.defaultOption) {
                    scope.defaultOption = "-- Please Select --";
                }

                scope.$watch("ngModel", (newValue, oldValue) => {
                    if (scope.viewOnly) {
                        scope.ngModelIsAnObject = _.isObject(newValue);
                    }
                });
            }
        };
    });

    appShared.directive('brooksonInputSelectSearch', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.select-search.html',
            scope: {
                ngModel: '=',
                ngChange: '=',
                ngDisabled: '=',
                optionsData: '=',
                optionsExpression: '@',
                field: '@',
                isRequired: '@',
                label: '@',
                ngRepeat: '@',
                defaultOption: '@',
                additionalData: '=',
                noDefault: '=',
                showSpinner: '=',
                viewOnly: '=',
                viewOnlyProperty: '@',
                typeaheadValue : '@'
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;               
            }
        };
    });

    appShared.directive('brooksonNumber', () => {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: (scope, element, attrs: any, ngModel: any) => {

                //format text going to user (model to view)
                ngModel.$formatters.push((value) => {
                    if (value === 0) {
                        if (attrs.showZero) {
                            return 0;
                        }

                        return null;
                    }

                    if (!value || isNaN(value)) return value;
                    //return _.round(value, 2);

                    return value;
                });

                //format text from the user (view to model)
                ngModel.$parsers.push((value) => {
                    if (!value || isNaN(value)) return value;
                    //return _.round(value, 2);

                    return value;
                });

                element.bind('mousewheel', () => {
                    element.blur();
                });
            }
        };
    });

    appShared.directive('brooksonInputNumber', ['$timeout', ($timeout) => {
        return {
            restrict: 'AE',
            require: ['^form', '^ngModel'],
            templateUrl: 'src/app/shared/views/inputs/brookson.input.number.html',
            scope: {
                ngModel: '=',
                label: '@',
                currencyCode: '=',
                field: '@',
                isRequired: '=',
                ngReadonly: '=',
                ngChange: '&',
                ngMin: '=',
                ngDisabled: '=',
                placeholder: '@',
                step: '=?',
                wholeNumber: '=',
                showZero: '=',
                max: '=',
                viewOnly: '=',
                minCustomMessage: '@?',
                maxCustomMessage: '@?'
            },
            link: (scope: any, el, attrs, require) => {
                scope.form = require[0];

                el.bind('change', () => {
                    scope.ngModel = _.round(scope.ngModel, 2);

                    $timeout(() => {
                        scope.$apply();
                    });
                });

                var changeInternal = () => {
                    if (scope.ngChange) {
                        $timeout(() => {
                            scope.ngChange();
                        });
                    }
                };

                scope.changeInternal = changeInternal;
            },
            controller: [
                "$scope", '$filter', "currency.enums", ($scope, $filter, currencyEnums) => {
                    $scope.currencyEnums = currencyEnums;
                    $scope.step = $scope.step ? $scope.step : 0.01;

                    activate();

                    function activate() {
                        $scope.$watch('wholeNumber', () => {
                            $scope.ngPattern = $scope.wholeNumber ? /^[0-9]{1,7}$/ : '';
                        }, true);
                    }
                }
            ]
        };
    }]);

    var brooksonInlineController = ["$scope", ($scope) => {
        $scope.isEditMode = false;
        $scope.defaultValue = null;
        $scope.$watch("isEditMode", (newValue, oldValue) => {
            if (newValue === false) {
                $scope.ngModel = $scope.defaultValue;
            }
        });

        $scope.$watch("ngModel", (newValue, oldValue) => {
            if ((oldValue === "" || oldValue === null) && $scope.defaultValue === null) {
                $scope.defaultValue = newValue;
            } else if ($scope.defaultValue === null) {
                $scope.defaultValue = "";
            }
        });
    }];

    appShared.directive('brooksonInlineEdit', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.inline-edit.input.html',
            scope: {
                ngModel: '=',
                ngMaxlength: '=',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngDisabled: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: brooksonInlineController
        };
    });

    appShared.directive('brooksonInlineEditTextarea', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.inline-edit.textarea.html',
            scope: {
                ngModel: '=',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngMaxlength: '=',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: brooksonInlineController
        };
    });

    appShared.directive('brooksonInputBoolean', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.boolean.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                viewOnly: '=',
                isSelfAssessment: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;

            },
            controller: [
                "$scope", ($scope) => {
                    this.$onInit = () => { };
                }
            ]
        };
    });

    appShared.directive('brooksonInputCheckbox', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.checkbox.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                viewOnly: '=',
                ngChecked: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    this.$onInit = () => { };
                }
            ]
        };
    });

    appShared.directive('brooksonInputCheckButtons', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.check-buttons.html',
            scope: {
                buttons: '=',
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngMinlength: '=',
                ngMaxlength: '=',
                ngDisabled: '=',
                placeholder: '@',
                multiselect: '=',
                viewOnly: '=',
                additionalStyling: '@',
                isSelfAssessment: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    this.$onInit = () => { };

                    $scope.changed = (value) => {
                        if ($scope.multiselect) {
                            if (!$scope.ngModel || !Array.isArray($scope.ngModel)) $scope.ngModel = [];

                            if (_.includes($scope.ngModel, value)) {
                                _.remove($scope.ngModel, (item) => {
                                    return item === value;
                                });
                            } else {
                                $scope.ngModel.push(value);
                            }
                        } else {
                            $scope.ngModel = value;
                        }
                    };

                    $scope.isSelected = (value) => {
                        if ($scope.multiselect) {
                            return _.includes($scope.ngModel, value);
                        } else {
                            return $scope.ngModel === value;
                        }
                    };

                    $scope.viewValue = (): string => {
                        var button = _.find<EnumObj>($scope.buttons, (button) => {
                            return button.id === $scope.ngModel;
                        });

                        return button ? button.name : '';
                    };
                }
            ]
        };
    });

    appShared.directive('brooksonInputPercentage', ['$timeout', ($timeout) => {
        return {
            restrict: 'AE',
            require: ['^form', '^ngModel'],
            templateUrl: 'src/app/shared/views/inputs/brookson.input.percentage.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngMin: '=',
                ngDisabled: '=',
                placeholder: '@',
                step: '=?',
                wholeNumber: '=',
                showZero: '=',
                viewOnly: '=',
                max: '='
            },
            link: (scope: any, el, attrs, require) => {
                scope.form = require[0];

                el.bind('change', () => {
                    scope.ngModel = _.round(scope.ngModel, 2);

                    $timeout(() => {
                        scope.$apply();
                    });
                });
            },
            controller: [
                "$scope", '$filter', ($scope, $filter) => {
                    $scope.step = $scope.step ? $scope.step : 0.01;
                    $scope.ngPattern = $scope.wholeNumber ? /^[0-9]{1,7}$/ : '';
                }
            ]
        };
    }]);

    appShared.directive('brooksonInputTextarea', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.textarea.html',
            scope: {
                ngModel: '=',
                field: '@',
                label: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngMaxlength: '=',
                textboxStyle: '=',
                hideRemainingCharacters: '=',
                viewOnly: '=',
                blockTypingBeyondLimit: '@',
                ngChange: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    if ($scope.blockTypingBeyondLimit === "true") {
                        $scope.$watch("ngModel", (newValue, oldValue) => {
                            if (_.isUndefined(newValue) && oldValue) {
                                $scope.ngModel = oldValue;
                            }
                        });
                    }
                }
            ]
        };
    });

    appShared.directive('brooksonInputPhone', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.ngPattern = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
                    $scope.ngPatternMessage = "Not a valid phone number";
                }
            ]
        };
    });

    appShared.directive('brooksonInputTradingName', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.isLimitedCompany = (() => {
                        var regEx = /\w*(limited|ltd)\b/i;
                        return {
                            test: (value) => {
                                return !regEx.test(value);
                            }
                        };
                    })();

                    $scope.ngPattern = $scope.isLimitedCompany;
                    $scope.ngPatternMessage = "Limited company income should not be input in self employment section";
                }
            ]
        };
    });


    appShared.directive('brooksonInputBankName', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngDisabled: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.isLimitedCompany = (() => {
                        var regEx = /\w*(limited|ltd)\b/i;
                        return {
                            test: (value) => {
                                return !regEx.test(value);
                            }
                        };
                    })();

                    $scope.ngPattern = $scope.isLimitedCompany;
                    $scope.ngPatternMessage = "You appear to have entered ‘Limited’ or ‘Ltd’ in the Account Name please ensure this is your personal bank account not your company’s.";
                }
            ]
        };
    });

    appShared.directive('brooksonInputUtrNumber', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngChange: '=',
                ngDisabled: '=',
                placeholder: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;

                var valueWatch = scope.$watch(`form.${scope.field}.$viewValue`, (newValue, oldValue) => {
                    if (oldValue) valueWatch();

                    setNgReadonly();
                }, true);

                function setNgReadonly() {
                    scope.ngReadonly = new RegExp(scope.ngPattern).test(scope.ngModel);
                }
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.ngPattern = /^[0-9]{10}$/;
                    $scope.ngPatternMessage = "Not a valid UTR number";
                }
            ]
        };
    });

    appShared.directive('brooksonMaskedInput', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.mask.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngMinlength: '=',
                ngMaxlength: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngPattern: '=',
                ngPatternMessage: '@',
                viewOnly: '=',
                uiMask: '@',
                uiMaskplaceholder: '@'
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.inputType = 'text';
                }
            ]
        };
    });

    appShared.directive('brooksonInputTextOnly', () => {
         return {
             restrict: 'A',
             require: 'ngModel',
             link: (scope, elem, attrs, ngModel: any) =>{
                 ngModel.$parsers.push((viewValue) => {
                   var reg = /^[ a-zA-Z0-9]*$/;
                   // if view values matches regexp, update model value
                   if (viewValue.match(reg)) {
                     return viewValue;
                   }
                   // keep the model value as it is
                   var transformedValue = ngModel.$modelValue;
                   ngModel.$setViewValue(transformedValue);
                   ngModel.$render();
                   return transformedValue;
                 });
             }
         };      
     });
    
     appShared.directive('brooksonInputTextRestrict', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text.restrict.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngMinlength: '=',
                ngMaxlength: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngPattern: '=',
                ngPatternMessage: '@',
                viewOnly: '='
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.inputType = 'text';
                    // $scope.expression = /^\d{2}$/;
                    // $scope.expression =  $scope.ngPattern;
                }
            ]
        };
    });

    appShared.directive('brooksonInputTextAddon', () => {
        return {
            restrict: 'AE',
            require: '^form',
            templateUrl: 'src/app/shared/views/inputs/brookson.input.text-addon.html',
            scope: {
                ngModel: '=',
                label: '@',
                field: '@',
                isRequired: '@',
                ngReadonly: '=',
                ngChange: '=',
                ngMinlength: '=',
                maxlength: '=',
                ngMaxlength: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngPattern: '=',
                ngPatternMessage: '@',
                viewOnly: '=',
                uiMask: '@',
                uiMaskplaceholder: '@',
                addontext: '@',
                inputType: '@'
            },
            link: (scope: any, el, attrs, formCtrl) => {
                scope.form = formCtrl;
            },
            controller: [
                "$scope", ($scope) => {
                    $scope.inputType = 'text';
                }
            ]
        };
    });

})();
